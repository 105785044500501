import * as React from "react";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownItem from "react-bootstrap/DropdownItem";
import PropTypes from "prop-types";
import { trackCustomEvent } from "../lib/TrackingHelper";
import { ReactComponent as Speechbubble } from "../images/icon-speechbubble.svg";

const LangugeSwitch = function LangugeSwitch({ className, location }) {
  const { languages, originalPath, language } = useI18next();
  const otherLanguages = languages.filter((lng) => {
    return lng !== language;
  });
  return (
    <Dropdown
      className={`language-switcher text-white text-decoration-none shadow-none text-uppercase ${className}`}
      style={{
        fontFamily: "Axiforma",
        fontWeight: "bold",
        width: "30px",
      }}
    >
      <Speechbubble width={30} height={30} />
      <Dropdown.Toggle
        variant="link"
        className="text-white text-decoration-none shadow-none text-uppercase p-0 m-0"
        style={{
          position: "absolute",
          top: "2px",
          left: "0px",
          width: "30px",
          fontSize: "13px",
        }}
      >
        {language.split("-")[0]}
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="bg-secondary shadow-none p-0 rounded-0 shadow-none border-0 rounded-bottom"
        style={{
          width: "30px",
          minWidth: "30px",
          top: "19px",
        }}
      >
        {otherLanguages.map((lng) => (
          <DropdownItem
            key={`language-switch-item-${lng}`}
            className="text-uppercase px-0 py-15 shadow-none border-none"
            style={{
              width: "30px",
              minWidth: "30px",
              fontSize: "13px",
              textAlign: "center",
            }}
            as={Link}
            to={originalPath}
            language={lng}
            onClick={() => {
              trackCustomEvent("change_language", {
                selected_language: lng,
                element_location: location,
              });
            }}
          >
            {lng.split("-")[0]}
          </DropdownItem>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
LangugeSwitch.defaultProps = {
  className: "",
  location: "",
};

LangugeSwitch.propTypes = {
  className: PropTypes.string,
  location: PropTypes.string,
};

export default LangugeSwitch;
