/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import * as React from "react";
import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import "../scss/App.scss";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { InView } from "react-intersection-observer";
import Button from "react-bootstrap/Button";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { trackCustomEvent } from "../lib/TrackingHelper";
import Layout from "../components/Layout";
import welleUrl from "../images/welle.svg";
import { ReactComponent as IconCross } from "../images/icon-cross.svg";
import orangeCircleUrl from "../images/circle-orange.svg";
import grayCircleUrl from "../images/circle.svg";
import Icon from "../components/Icon";
import {
  ContentfulPagePropType,
  GatsbySitePropType,
  LocalesPropType,
  NavigationPropType,
} from "../lib/PropTypeShapes";
import SectionIntroRow from "../components/SectionIntroRow";
import RequestTestpackageModal from "../components/RequestTestpackageModal";
import { getBlockBySlug, getElementByType } from "../lib/ContentfulHelper";

const UnderfloorContainer = function UnderfloorContainer({ data }) {
  const { t } = useTranslation();
  return (
    <InView threshold={[1]}>
      {({ inView, ref }) => (
        <div
          className={`speechbubble-animation position-relative ${
            inView ? "in" : "out"
          }`}
          style={{ width: "322px", height: "322px" }}
          ref={ref}
        >
          <div
            className={`speechbubble ff-sans bg-primary text-white position-absolute rounded-1 ${
              inView ? "slide-in-bottom" : "out"
            }`}
          >
            <small>{t("underfloor-container.info")}</small>
          </div>
          <GatsbyImage
            className="position-absolute closed"
            style={{ top: "0", left: "0" }}
            image={getImage(data.containerClosed)}
            width="100%"
            alt={t("underfloor-container-closed.alt")}
          />
          <GatsbyImage
            className="position-absolute open"
            style={{ top: "0", left: "0" }}
            image={getImage(data.containerOpen)}
            width="100%"
            alt={t("underfloor-container-open.alt")}
          />
        </div>
      )}
    </InView>
  );
};
const Sensor = function Sensor({ data }) {
  const { t } = useTranslation();
  return (
    <InView threshold={[0.75]}>
      {({ inView, ref }) => (
        <div
          className={`speechbubble-animation position-relative ${
            inView ? "in" : "out"
          }`}
          style={{ width: "100%", height: "100%", direction: "ltr" }}
          ref={ref}
        >
          <div
            className={`speechbubble ff-sans bg-primary text-white position-absolute rounded-1 ${
              inView ? "slide-in-bottom" : "out"
            }`}
          >
            <small>
              <strong className="d-block text-black mb-1">
                {t("sensor.info.title")}
              </strong>
              {t("sensor.info.text")}
            </small>
          </div>
          <GatsbyImage
            className="position-absolute closed"
            style={{ top: "0", left: "0" }}
            image={getImage(data.sensorClosed)}
            width="100%"
            alt={t("sensor.alt")}
          />
          <GatsbyImage
            className="position-absolute open"
            style={{ top: "0", left: "0" }}
            image={getImage(data.sensorOpen)}
            width="100%"
            alt={t("sensor-transparent.alt")}
          />
        </div>
      )}
    </InView>
  );
};

const ProductPage = function ProductPage({ data, location }) {
  const { contentBlocks } = data.contentfulPage;
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => {
    setShowModal(false);
    trackCustomEvent("close_modal", {
      content: "Button",
      label: "Close Modal - Request a test package",
    });
  };
  const handleShowModal = () => {
    setShowModal(true);
    trackCustomEvent("open_modal", {
      category: "Button",
      action: "Click",
      label: "Request a test package",
    });
  };

  const intro = getBlockBySlug(contentBlocks, "intro-vartios").contentElements;
  const imageVartios = getBlockBySlug(contentBlocks, "image-vartios");
  const imageBin = getElementByType(imageVartios, "ContentfulImage");
  const challenges = getBlockBySlug(contentBlocks, "challenges");
  const challengesList = getElementByType(challenges, "ContentfulList");
  const solutionIntro = getBlockBySlug(contentBlocks, "solution-intro");
  const solution = getBlockBySlug(contentBlocks, "solution");
  const solutionList = getElementByType(solution, "ContentfulList");
  const advantages = getBlockBySlug(contentBlocks, "advantages");
  const advantagesImage = getElementByType(advantages, "ContentfulImage");
  const cta = getBlockBySlug(contentBlocks, "cta-test-package");
  const requestContent = getBlockBySlug(contentBlocks, "request-test-package");

  const behindTheScenes = getBlockBySlug(
    contentBlocks,
    "behind-the-scenes"
  ).contentElements;

  const behindTheScenesGrid = behindTheScenes.filter((element) => {
    return element.internal.type === "ContentfulImageGrid";
  })[0].images;

  const behindTheScenesButton = behindTheScenes.filter((e) => {
    return e.internal.type === "ContentfulButton";
  })[0];

  const breakpoints = useBreakpoint();

  const [matchingBreakpoint, setMatchingBreakpoint] = useState({});

  useEffect(() => {
    if (Object.keys(breakpoints).length > 0) {
      const breakpointArray = Object.entries(breakpoints);
      const matches = breakpointArray.filter((breakpoint) => {
        return breakpoint[1];
      });
      setMatchingBreakpoint(matches[matches.length - 1][0]);
    }
  }, [breakpoints]);

  const heroImageStyles = {
    xs: { width: "500px", height: "441px" },
    md: { width: "860px", height: "440px" },
    lg: { width: "1381px", height: "658px" },
    xl: { width: "1381px", height: "658px" },
  };

  const heroImagePosition = {
    xs: "-90px -50px",
    md: "-15px -65px",
    lg: "10% 90%",
    xl: "10% 90%",
  };

  const galleryImage1Styles = {
    xs: { width: "100%", height: "250px" },
    md: { width: "100%", height: "417px" },
    lg: { width: "100%", height: "546px" },
    xl: { width: "100%", height: "546px" },
  };
  const galleryImage2Styles = {
    xs: { width: "100%", height: "250px" },
    md: { width: "100%", height: "545px" },
    lg: { width: "100%", height: "752px" },
    xl: { width: "100%", height: "752px" },
  };
  const galleryImage3Styles = {
    xs: { width: "100%", height: "250px" },
    md: { width: "100%", height: "454px" },
    lg: { width: "100%", height: "481px" },
    xl: { width: "100%", height: "481px" },
  };

  return (
    <Layout data={data} location={location}>
      {/* Intro */}
      <Container className="mb-5">
        <SectionIntroRow contentElements={intro} />
      </Container>

      {/* Intro Image */}
      <Container
        fluid
        as="section"
        className="hero-image mb-4 mb-md-6 position-relative"
      >
        <Container
          style={{ direction: imageBin.align === "right" ? "rtl" : "ltr" }}
        >
          <GatsbyImage
            className={`vartios-hero ${matchingBreakpoint}`}
            style={heroImageStyles[matchingBreakpoint]}
            objectPosition={heroImagePosition[matchingBreakpoint]}
            image={getImage(imageBin.image)}
            alt={imageBin.image.title}
          />
        </Container>

        <div
          style={{
            width: "100%",
            background: `url(${welleUrl})`,
            height: "102px",
            position: "absolute",
            top: "calc(50% - 102px/5)",
          }}
        />
      </Container>

      {/* Intro Image Text */}
      <Container className="mb-6 mb-lg-7">
        <SectionIntroRow contentElements={imageVartios.contentElements} />
      </Container>

      {/* Challenges */}
      <Container className="mb-6 mb-md-0 mb-lg-71 position-relative">
        <SectionIntroRow
          contentElements={challenges.contentElements}
          headlineClassName="h1"
          className="mb-4 mb-md-5"
        />
        <Row>
          <Col xs={12} md={11} lg={6}>
            {challengesList.listItems.map((item) => {
              return (
                <div key={item.id} className="ps-5 ps-md-0">
                  <h3 className="text-dark mb-2">
                    <IconCross
                      width={18}
                      height={18}
                      className="align-top"
                      style={{ marginLeft: "-28px", marginRight: "10px" }}
                    />
                    {item.headline}
                  </h3>
                  <span
                    className="mb-4 d-block"
                    dangerouslySetInnerHTML={{
                      __html: item.text.childMarkdownRemark.html,
                    }}
                  />
                </div>
              );
            })}
          </Col>
        </Row>
        <div
          style={{
            backgroundImage: `url(${grayCircleUrl}), url(${orangeCircleUrl})`,
          }}
          className="inview underfloor-container d-flex align-items-center justify-content-center"
        >
          <UnderfloorContainer data={data} />
        </div>
      </Container>

      {/* Solution Intro */}
      <Container className="mb-71 mb-md-0">
        <SectionIntroRow
          contentElements={solutionIntro.contentElements}
          headlineClassName="h1"
          cols={{ md: 11, lg: 8 }}
        />
      </Container>

      {/* Solution */}

      <Container
        fluid
        className="solution bg-primary position-relative py-7 py-lg-61 pb-7 pt-md-71 pb-md-7 my-md-7 mt-7 mb-6"
        style={{ maxWidth: "1250px" }}
      >
        <Container style={{ direction: "rtl" }}>
          <Row style={{ direction: "ltr" }}>
            <Col
              style={{ maxHeight: "364px" }}
              md={{ span: 12 }}
              lg={{ span: 5, order: 2, offset: 1 }}
              className="d-flex align-items-center mb-md-2 mb-lg-0"
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: solution.contentElements
                    .filter((e) => {
                      return e.internal.type === "ContentfulHeadline";
                    })[0]
                    .text.childMarkdownRemark.html.replace(
                      "<small>",
                      "<small class='ff-serif d-block mt-2'>"
                    ),
                }}
              />
            </Col>
            <Col md={{ span: 12 }} lg={{ span: 6, order: 1 }}>
              <Accordion flush>
                {solutionList.listItems.map((item, index) => {
                  return (
                    <Accordion.Item key={item.id} eventKey={index}>
                      <Accordion.Header
                        as="div"
                        onClick={(e) => {
                          e.preventDefault();
                          trackCustomEvent("click", {
                            category: "Accordion",
                            label: item.headline,
                          });
                        }}
                      >
                        <span className="accordion-header-content d-flex flex-column align-items-start">
                          <Icon name={item.icon} width="40px" height="40px" />

                          <h3 className="my-1">{item.headline}</h3>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: item.text.childMarkdownRemark.html,
                            }}
                          />
                        </span>
                      </Accordion.Header>
                      <Accordion.Body className="bg-white markdown markdown-icon-list">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: item.longText.childMarkdownRemark.html,
                          }}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </Col>
          </Row>
        </Container>
        <div className="inview sensor d-flex align-items-center justify-content-center">
          <Sensor data={data} />
        </div>
      </Container>

      {/* Advantages */}
      <Container className="advantages mb-0">
        <Row>
          <Col
            md={12}
            lg={4}
            className="d-flex d-md-block justify-content-center mb-4 mb-md-0"
          >
            <div className="imac">
              <GatsbyImage
                image={getImage(advantagesImage.image)}
                alt={advantagesImage.image.title}
                width={380}
                height={333}
              />
            </div>
          </Col>
          <Col md={{ span: 9, offset: 3 }} lg={{ span: 8 }}>
            <span
              className="h1 mb-3"
              dangerouslySetInnerHTML={{
                __html: advantages.contentElements
                  .filter((e) => {
                    return e.internal.type === "ContentfulHeadline";
                  })[0]
                  .text.childMarkdownRemark.html.replace(
                    "<h2>",
                    "<h2 class='h1 mb-2 mb-md-5'>"
                  ),
              }}
            />

            <Row lg={2} xs={1} className="advantages-list">
              <Col
                dangerouslySetInnerHTML={{
                  __html: advantages.contentElements.filter((e) => {
                    return e.internal.type === "ContentfulMarkdownText";
                  })[0].body.childMarkdownRemark.html,
                }}
              />
              <Col
                dangerouslySetInnerHTML={{
                  __html: advantages.contentElements.filter((e) => {
                    return e.internal.type === "ContentfulMarkdownText";
                  })[1].body.childMarkdownRemark.html,
                }}
              />
            </Row>
            <Row className="mt-md-4 mt-lg-0 advantages-abo">
              <Col
                dangerouslySetInnerHTML={{
                  __html: advantages.contentElements.filter((e) => {
                    return e.internal.type === "ContentfulMarkdownText";
                  })[2].body.childMarkdownRemark.html,
                }}
              />
            </Row>
          </Col>
        </Row>
      </Container>

      {/* CTA */}
      <Container fluid="lg" className="cta mt-7 mt-md-5 mt-lg-7">
        <RequestTestpackageModal
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          content={requestContent}
        />
        <Row className="g-0 bg-white shadow-sm rounded-1">
          <Col xs={{ order: 2 }} md={8} lg={{ order: 1 }}>
            <div className="px-4 ps-md-6 pe-md-6 pe-lg-7 pt-0 pt-md-5 pt-lg-3 pb-4 position-relative">
              <h2 className="mt-0 mt-lg-3 mt-md-1 mb-1 small text-primary">
                {cta.contentElements[0].headline}
              </h2>
              <span
                dangerouslySetInnerHTML={{
                  __html: cta.contentElements[0].text.childMarkdownRemark.html,
                }}
              />
              <div
                className="position-absolute d-md-block d-none"
                style={{ bottom: "-20px" }}
              >
                <Button variant="primary" onClick={handleShowModal}>
                  <Icon
                    className="me-2"
                    name={cta.contentElements[0].buttons[0].icon}
                    width="19px"
                    height="19px"
                    style={{
                      verticalAlign: "sub",
                      marginTop: "1px",
                    }}
                  />
                  {cta.contentElements[0].buttons[0].text}
                </Button>
              </div>
            </div>
          </Col>
          <Col
            xs={{ order: 1 }}
            md={{ span: 4 }}
            lg={{ order: 2 }}
            className="d-md-flex d-lg-block position-relative justify-content-center align-items-center"
          >
            <div className="d-flex justify-content-center d-lg-block">
              <GatsbyImage
                className="sensor-testpackage"
                image={getImage(data.sensorImage)}
                alt={imageBin.image.title}
              />
            </div>
          </Col>
        </Row>
        <div
          className="d-flex d-md-none justify-content-center"
          style={{ marginTop: "-20px" }}
        >
          <Button variant="primary" onClick={handleShowModal}>
            <Icon
              className="me-2"
              name={cta.contentElements[0].buttons[0].icon}
              width="19px"
              height="19px"
              style={{
                verticalAlign: "sub",
                marginTop: "1px",
              }}
            />
            {cta.contentElements[0].buttons[0].text}
          </Button>
        </div>
      </Container>

      {/* Behind the Scenes Text */}
      <Container className="mt-7">
        <Row>
          <Col md={10} lg={4}>
            <span
              dangerouslySetInnerHTML={{
                __html:
                  behindTheScenes[0].text.childMarkdownRemark.html.replace(
                    /<h2>/,
                    `<h2 class="h1 mb3">`
                  ),
              }}
            />
          </Col>
          <Col md={12} lg={8}>
            <span
              dangerouslySetInnerHTML={{
                __html:
                  behindTheScenes[1].body.childMarkdownRemark.html.replace(
                    "<p>",
                    `<p class="lead">`
                  ),
              }}
            />
          </Col>
        </Row>
      </Container>

      {/* Behind the Scenes Grid */}
      <Container fluid className="position-relative">
        <Container className="mt-5 mt-md-5 mt-lg-3 position-relative">
          <Row className="align-items-end">
            <Col xs={6} md={6} lg={4}>
              <GatsbyImage
                style={galleryImage1Styles[matchingBreakpoint]}
                image={getImage(behindTheScenesGrid[0])}
                className={`${matchingBreakpoint}`}
                alt={behindTheScenesGrid[0].title}
                width={361}
                height={546}
              />
            </Col>
            <Col xs={6} md={6} lg={8} className="text-center">
              <GatsbyImage
                style={galleryImage2Styles[matchingBreakpoint]}
                // objectPosition="10% 90%"
                className={`${matchingBreakpoint}`}
                image={getImage(behindTheScenesGrid[1])}
                alt={behindTheScenesGrid[1].title}
                width={736}
                height={752}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col
              xs={{ order: 2 }}
              md={{ span: 6, order: 1 }}
              lg={4}
              className="mt-3 mt-md-0"
            >
              <div className="deine-chance ps-4 pe-4 py-6 py-md-5 bg-secondary h-100 position-relative flex-column fw-light">
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      behindTheScenes[3].body.childMarkdownRemark.html.replace(
                        /<h2>/,
                        "<h2 class='text-white small'>"
                      ),
                  }}
                />
              </div>
              <div className="d-flex justify-content-center">
                <Button
                  key={behindTheScenesButton.id}
                  variant={behindTheScenesButton.variant}
                  href={behindTheScenesButton.externalLink}
                  style={{ marginTop: "-20px", zIndex: "10" }}
                  onClick={() => {
                    trackCustomEvent("click", {
                      category: "Button",
                      label: behindTheScenesButton.text,
                      link: behindTheScenesButton.externalLink,
                    });
                  }}
                >
                  <Icon
                    name={behindTheScenesButton.icon}
                    width="19px"
                    height="19px"
                    className="me-3"
                    style={{
                      verticalAlign: "sub",
                      marginTop: "1px",
                    }}
                  />

                  <span className="d-md-none">
                    {behindTheScenesButton.buttonText.replace(
                      /\+49 \(0\)40 60060160/,
                      t("cta.call")
                    )}
                  </span>
                  <span className="d-none d-md-inline-block">
                    {behindTheScenesButton.buttonText}
                  </span>
                </Button>
              </div>
            </Col>
            <Col xs={{ order: 1 }} md={{ span: 6, order: 2 }} lg={5}>
              <GatsbyImage
                style={galleryImage3Styles[matchingBreakpoint]}
                className={`${matchingBreakpoint}`}
                // objectPosition="10% 90%"
                image={getImage(behindTheScenesGrid[2])}
                alt={behindTheScenesGrid[2].title}
                width={451}
                height={481}
              />
            </Col>
          </Row>
          <div className="orange-circle-vartios" />
        </Container>
        <div className="welle-vartios" />
      </Container>
    </Layout>
  );
};

export default ProductPage;

ProductPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    site: GatsbySitePropType,
    topNavigation: NavigationPropType,
    legalNavigation: NavigationPropType,
    linkNavigation: NavigationPropType,
    contentfulPage: ContentfulPagePropType,
    locales: LocalesPropType,
  }).isRequired,
};

export const query = graphql`
  query ProductPage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    legalNavigation: contentfulNavigation(
      slug: { eq: "legal-info" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    linkNavigation: contentfulNavigation(
      slug: { eq: "links" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    topNavigation: contentfulNavigation(
      slug: { eq: "top-navigation" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    topOffcanvasNavigation: contentfulNavigation(
      slug: { eq: "top-offcanvas" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    contentfulPage(
      slug: { eq: "smart-waste" }
      node_locale: { eq: $language }
    ) {
      ...ContentfulPageFragment
    }

    sensorImage: file(relativePath: { eq: "adhoc-sensor_testpaket.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    sensorClosed: file(relativePath: { eq: "adhoc-sensor_zu.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    sensorOpen: file(relativePath: { eq: "adhoc-sensor_offen.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    containerOpen: file(
      relativePath: { eq: "adhoc-unterflurcontainer_offen.png" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    containerClosed: file(
      relativePath: { eq: "adhoc-unterflurcontainer_zu.png" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
  }
`;
