import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import React from "react";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Alert from "react-bootstrap/Alert";
import { trackCustomEvent } from "../lib/TrackingHelper";

const ContactForm = function ContactForm() {
  const { t } = useTranslation();

  const schema = Yup.object().shape({
    fullname: Yup.string().required(),
    email: Yup.string().required(),
    message: Yup.string().required(),
    privacyPolicy: Yup.bool().oneOf([true], "Privacy Policy"),
  });

  const privacyPolicy = (
    <Trans i18nKey="order.form.privacy_policy">
      Ich habe die{" "}
      <a href="/privacy-policy" target="_blank">
        Datenschutzerklärung
      </a>{" "}
      gelesen und bin mit der Verarbeitung und Speicherung meiner Daten und der
      weiteren Kontaktaufnahme einverstanden. Diese Einwilligung kann jederzeit
      für die Zukunft per E-Mail an{" "}
      <a href="mailto:info@ad-hoc.com">info@ad-hoc.com</a> widerrufen werden.
    </Trans>
  );

  return (
    <Formik
      initialValues={{
        fullname: "",
        email: "",
        message: "",
        privacyPolicy: false,
      }}
      validationSchema={schema}
      onSubmit={async (values, { resetForm, setStatus, setSubmitting }) => {
        const newValues = { source: "contact", ...values };
        const formData = new FormData();

        const keys = Object.keys(newValues);
        keys.forEach((key) => {
          formData.append(key, newValues[key]);
        });

        axios
          .post("/send.php", formData, {
            headers: { Accept: "application/json" },
          })
          .then(() => {
            resetForm();
            setStatus({ success: true });
            setSubmitting(false);
            // resetForm()
          })
          .catch(() => {
            setStatus({ success: false });
            setSubmitting(false);
          });

        trackCustomEvent("submit_form", {
          form: "contact",
        });
        setSubmitting(false);
      }}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        isSubmitting,
        status,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          {status && status.success && (
            <Alert variant="primary" className="text-white">
              {t("contact.form.thank_you")}
            </Alert>
          )}
          {status && !status.success && (
            <Alert variant="danger">
              Leider ist ein Fehler aufgetreten. Bitte senden Sie für Ihre
              Anfrage eine E-Mail an{" "}
              <a className="text-white" href="mailto:info@ad-hoc.com">
                info@ad-hoc.com
              </a>{" "}
              .
            </Alert>
          )}
          <Row>
            <Form.Group
              as={Col}
              md={4}
              lg={5}
              className="mb-3"
              controlId="formBasicName"
            >
              <Form.Label>{`${t("contact.fullname")}*`}</Form.Label>
              <Form.Control
                type="text"
                name="fullname"
                value={values.fullname}
                onChange={handleChange}
                isValid={touched.fullname && !errors.fullname}
                isInvalid={!!errors.fullname}
                required
              />
            </Form.Group>
            <Form.Group
              as={Col}
              md={{ span: 4, offset: 1 }}
              lg={5}
              className="mb-3"
              controlId="formBasicEmail"
            >
              <Form.Label>{`${t("contact.email")}*`}</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                isValid={touched.email && !errors.email}
                isInvalid={!!errors.email}
                required
              />
            </Form.Group>
            <Form.Group
              as={Col}
              md={4}
              lg={5}
              className="mb-3"
              controlId="text"
            >
              <Form.Label>{`${t("contact.message")}*`}</Form.Label>
              <Form.Control
                as="textarea"
                name="message"
                value={values.message}
                onChange={handleChange}
                isValid={touched.message && !errors.message}
                isInvalid={!!errors.message}
                rows={5}
                required
              />
            </Form.Group>
          </Row>
          <Row className="mb-3 fw-light">
            <p>{`${t("required_field")}`}</p>
            <Form.Check
              type="checkbox"
              name="privacyPolicy"
              value={values.privacyPolicy}
              onChange={handleChange}
              isValid={touched.privacyPolicy && !errors.privacyPolicy}
              isInvalid={!!errors.privacyPolicy}
              required
              id="default-checkbox"
              label={privacyPolicy}
            />
          </Row>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            {t("contact.submit")}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;
