import * as React from "react";
import { Helmet } from "react-helmet";
import AxiformaLight from "../fonts/Axiforma-Light.woff2";
import AxiformaMedium from "../fonts/Axiforma-Medium.woff2";
import AxiformaThin from "../fonts/Axiforma-Thin.woff2";
import RobotoSlabLight from "../fonts/RobotoSlab-Light.woff2";
import RobotoSlabRegular from "../fonts/RobotoSlab-Regular.woff2";
import RobotoSlabBold from "../fonts/RobotoSlab-Bold.woff2";

const PreloadFonts = function PreloadFonts() {
  return (
    <Helmet>
      <link
        rel="preload"
        as="font"
        href={AxiformaLight}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={AxiformaMedium}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={AxiformaThin}
        type="font/woff2"
        crossOrigin="anonymous"
      />{" "}
      <link
        rel="preload"
        as="font"
        href={RobotoSlabLight}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={RobotoSlabRegular}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={RobotoSlabBold}
        type="font/woff2"
        crossOrigin="anonymous"
      />
    </Helmet>
  );
};

export default PreloadFonts;
