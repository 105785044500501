/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import * as React from "react";
import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from "react-bootstrap/Carousel";
import "../scss/App.scss";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { Helmet } from "react-helmet";
import chunk from "lodash.chunk";
import { trackCustomEvent } from "../lib/TrackingHelper";
import Layout from "../components/Layout";
import welleUrl from "../images/welle.svg";
import { ReactComponent as IconQuote } from "../images/icon-quote.svg";
import SectionIntroRow from "../components/SectionIntroRow";
import Icon from "../components/Icon";
import { getBlockBySlug, getElementByType } from "../lib/ContentfulHelper";
import {
  ContentfulPagePropType,
  GatsbySitePropType,
  LocalesPropType,
  NavigationPropType,
} from "../lib/PropTypeShapes";
import BlogPostCard from "../components/BlogPostCard";

const IndexPage = function IndexPage({ data, location }) {
  const { t } = useTranslation();
  const { contentBlocks } = data.contentfulPage;
  const intro = getBlockBySlug(contentBlocks, "intro");
  const imageSensorBlock = getBlockBySlug(contentBlocks, "sensor-hero-image");
  const imageSensor = getElementByType(imageSensorBlock, "ContentfulImage");
  const textBlock = getBlockBySlug(contentBlocks, "textblock-1");
  const vartiosBlock = getBlockBySlug(contentBlocks, "vartios");
  const vartiosList = getElementByType(vartiosBlock, "ContentfulList");
  const vartiosButton = getElementByType(vartiosBlock, "ContentfulButton");
  const testBlock = getBlockBySlug(contentBlocks, "testimonials");
  const testBlockTestimonials = getElementByType(testBlock, "ContentfulList");
  const ctaBlock = getBlockBySlug(contentBlocks, "cta-contact");
  const teamBlock = getBlockBySlug(contentBlocks, "team");
  const teamBlockGrid = getElementByType(teamBlock, "ContentfulImageGrid");
  const eushBlock = getBlockBySlug(contentBlocks, "eush-founding");
  const eushImage = getElementByType(eushBlock, "ContentfulImage");
  const eushText = getElementByType(eushBlock, "ContentfulMarkdownText");
  const breakpoints = useBreakpoint();

  const heroImageStyles = {
    xs: { width: "780px", height: "380px", direction: "ltr" },
    md: { width: "1154px", height: "440px" },
    lg: { width: "1381px", height: "658px" },
    xl: { width: "1381px", height: "658px" },
  };

  const heroImagePosition = {
    xs: "90px 80%",
    md: "50% 75%",
    lg: "10% 90%",
    xl: "10% 90%",
  };

  const ctaImageStyle = {
    xs: { width: "100%", height: "217px" },
    md: { width: "100%", height: "217px" },
    lg: { width: "100%", height: "437px" },
    xl: { width: "100%", height: "437px" },
  };

  const ctaImagePosition = {
    xs: "50% 50%",
    md: "50% 50%",
    lg: "30% -90px",
    xl: "30% -90px",
  };

  const galleryImage1Styles = {
    xs: { width: "100%", height: "250px" },
    md: { width: "100%", height: "440px" },
    lg: { width: "100%", height: "650px" },
    xl: { width: "100%", height: "650px" },
  };
  const galleryImage2Styles = {
    xs: { width: "100%", height: "212px" },
    md: { width: "100%", height: "250px" },
    xl: { width: "100%", height: "348px" },
  };
  const galleryImage3Styles = {
    xs: { width: "100%", height: "212px" },
    md: { width: "100%", height: "375px" },
    lg: { width: "100%", height: "500px" },
    xl: { width: "100%", height: "500px" },
  };

  const [matchingBreakpoint, setMatchingBreakpoint] = useState({});

  useEffect(() => {
    if (Object.keys(breakpoints).length > 0) {
      const breakpointArray = Object.entries(breakpoints);
      const matches = breakpointArray.filter((breakpoint) => {
        return breakpoint[1];
      });
      setMatchingBreakpoint(matches[matches.length - 1][0]);
    }
  }, [breakpoints]);

  return (
    <Layout data={data} location={location}>
      <Helmet />
      <Container as="section" className="mb-5">
        <SectionIntroRow contentElements={intro.contentElements} />
      </Container>

      <Container
        fluid
        as="section"
        className="hero-image mb-6 position-relative"
      >
        <Container
          className="mb-6 "
          style={{
            direction: imageSensor.align === "right" ? "rtl" : "ltr",
          }}
        >
          <GatsbyImage
            className={`index-hero ${matchingBreakpoint}`}
            style={heroImageStyles[matchingBreakpoint]}
            objectPosition={heroImagePosition[matchingBreakpoint]}
            image={getImage(imageSensor.image)}
            alt={imageSensor.image.title}
          />
        </Container>
        {imageSensorBlock.graphicalOverlays.filter((overlay) => {
          return overlay.type === "sinus";
        }).length > 0 && (
          <div
            style={{
              width: "100%",
              background: `url(${welleUrl})`,
              height: "102px",
              position: "absolute",
              top: "calc(50% - 102px/5)",
            }}
          />
        )}
      </Container>

      <Container as="section" className="mb-71">
        <SectionIntroRow contentElements={textBlock.contentElements} />
      </Container>

      <Container
        fluid
        as="section"
        className="bg-secondary py-7 py-md-7 position-relative"
        style={{ maxWidth: "1250px" }}
      >
        <Container>
          <div className="vartios-box-image">
            <GatsbyImage alt="sensor" image={getImage(data.sensor)} />
          </div>
          <SectionIntroRow
            contentElements={vartiosBlock.contentElements}
            headlineClassName="h1"
          />
          <Row className="mt-5 mt-md-5 mb-md-2">
            {vartiosList.listItems.map((item) => {
              return (
                <Col xs={12} md={8} lg={4} key={item.id} className="mb-3">
                  {item.icon && (
                    <Icon name={item.icon} width="60px" height="60px" />
                  )}
                  <h3 className="mt-3 mb-2">{item.headline}</h3>
                  <p
                    className="fw-light"
                    dangerouslySetInnerHTML={{
                      __html: item.text.childMarkdownRemark.html.replace(
                        /(<p>|<\/p>)/i,
                        ""
                      ),
                    }}
                  />
                </Col>
              );
            })}
          </Row>
          <Link
            onClick={() => {
              trackCustomEvent("click", {
                category: "Button",
                lebel: "varitos Produktseite",
              });
            }}
            className="btn btn-primary"
            to={`/${vartiosButton.internalLink.slug}`}
          >
            {vartiosButton.buttonText}
          </Link>
        </Container>
      </Container>

      <Container
        as="section"
        className="mt-5 mt-md-6 mt-lg-8 mb-7 position-relative"
      >
        <SectionIntroRow
          contentElements={testBlock.contentElements}
          headlineClassName="h1"
        />
        <div className="mt-3 mt-md-5">
          <Carousel className="d-block d-md-none">
            {chunk(testBlockTestimonials.listItems, 1).map((testimonials) => {
              return (
                <Carousel.Item>
                  <Row sm={1}>
                    {testimonials.map((testimonial) => {
                      return (
                        <Col key={testimonial.id}>
                          <div className="bg-white rounded shadow-sm px-4 pt-5 pb-5">
                            <div className="d-flex border-bottom pb-3">
                              <GatsbyImage
                                image={getImage(testimonial.image)}
                                alt={testimonial.image.title}
                                className="rounded-circle me-3"
                                width={56}
                                height={56}
                              />
                              <div className="d-flex flex-column justify-content-center">
                                <h5 className="fw-medium mb-1">
                                  {testimonial.name}
                                </h5>
                                <small className="text-muted">
                                  {testimonial.company}, {testimonial.title}
                                </small>
                              </div>
                            </div>
                            <div
                              className="pt-3"
                              dangerouslySetInnerHTML={{
                                __html:
                                  testimonial.text.childMarkdownRemark.html,
                              }}
                            />

                            <IconQuote
                              width={24}
                              height={22}
                              className="float-end me-2"
                            />
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </Carousel.Item>
              );
            })}
          </Carousel>

          <Carousel className="d-none d-md-block">
            {chunk(testBlockTestimonials.listItems, 2).map((testimonials) => {
              return (
                <Carousel.Item>
                  <Row md={2} lg={2}>
                    {testimonials.map((testimonial) => {
                      return (
                        <Col key={testimonial.id}>
                          <div className="bg-white rounded shadow-sm px-4 pt-5 pb-5">
                            <div className="d-flex border-bottom pb-3">
                              <GatsbyImage
                                image={getImage(testimonial.image)}
                                alt={testimonial.image.title}
                                className="rounded-circle me-3"
                                width={56}
                                height={56}
                              />
                              <div className="d-flex flex-column justify-content-center">
                                <h5 className="fw-medium mb-1">
                                  {testimonial.name}
                                </h5>
                                <small className="text-muted">
                                  {testimonial.company}, {testimonial.title}
                                </small>
                              </div>
                            </div>
                            <div
                              className="pt-3"
                              dangerouslySetInnerHTML={{
                                __html:
                                  testimonial.text.childMarkdownRemark.html,
                              }}
                            />

                            <IconQuote
                              width={24}
                              height={22}
                              className="float-end me-2"
                            />
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
        <div className="greyCircle" />
      </Container>

      <Container fluid="lg" as="section" className="cta ">
        <Row className="g-0">
          <Col xs={{ order: 2 }} lg={8}>
            <div className="bg-secondary px-3 px-md-6 px-lg-5 pe-xl-7 py-4 py-md-5 py-lg-6 position-relative">
              <h2 className="small">{ctaBlock.contentElements[0].headline} </h2>
              <span
                className="fw-light"
                dangerouslySetInnerHTML={{
                  __html:
                    ctaBlock.contentElements[0].text.childMarkdownRemark.html,
                }}
              />

              <div
                className="d-none d-lg-block position-absolute"
                style={{ bottom: "-20px" }}
              >
                {ctaBlock.contentElements[0].buttons.map((button) => {
                  return (
                    <Button
                      key={button.id}
                      variant={button.variant}
                      href={button.externalLink}
                      className="me-2"
                      onClick={() => {
                        trackCustomEvent("click", {
                          category: "Button",
                          label: button.text,
                        });
                      }}
                    >
                      <Icon
                        className="me-2 d-none d-md-inline-block"
                        name={button.icon}
                        width="19px"
                        height="19px"
                        style={{
                          verticalAlign: "sub",
                          marginTop: "1px",
                        }}
                      />

                      {button.text}
                    </Button>
                  );
                })}
              </div>
            </div>
          </Col>

          <Col
            className="cta-image"
            xs={{ order: 1 }}
            lg={{ span: 4, order: 2 }}
          >
            <GatsbyImage
              style={ctaImageStyle[matchingBreakpoint]}
              objectPosition={ctaImagePosition[matchingBreakpoint]}
              image={getImage(ctaBlock.contentElements[0].image)}
              alt="ctaBlock.contentElements[0].image.title"
              className={`${matchingBreakpoint}`}
            />
          </Col>
        </Row>
        <div
          className="d-flex d-lg-none position-relative justify-content-center align-items-center"
          style={{ marginTop: "-20px", zIndex: "10" }}
        >
          {ctaBlock.contentElements[0].buttons.map((button, i) => {
            return (
              <Button
                key={button.id}
                variant={button.variant}
                href={button.externalLink}
                className={`${i === 0 ? "me-2" : ""}`}
                onClick={() => {
                  trackCustomEvent("click", {
                    category: "Button",
                    label: button.text,
                  });
                }}
              >
                <Icon
                  className="me-2"
                  name={button.icon}
                  width="19px"
                  height="19px"
                  style={{
                    verticalAlign: "sub",
                    marginTop: "1px",
                  }}
                />

                <span
                  dangerouslySetInnerHTML={{
                    __html: button.text
                      .replace(
                        "Direkt",
                        "<span class='d-none d-md-inline-block'>Direkt</span>"
                      )
                      .replace(/\+49 \(0\)40 60060160/, t("cta.call")),
                  }}
                />
              </Button>
            );
          })}
        </div>
      </Container>

      <Container
        as="section"
        className="mt-5 mt-md-7 mt-lg-8 position-relative"
      >
        <SectionIntroRow
          className="mb-2 mb-md-5"
          contentElements={teamBlock.contentElements}
          headlineClassName="h1"
        />

        <Row>
          <Col md={12}>
            <GatsbyImage
              style={galleryImage1Styles[matchingBreakpoint]}
              image={getImage(teamBlockGrid.images[0])}
              alt={teamBlockGrid.images[0].title}
              width="100%"
              height="650px"
              className={`mb-3 ${matchingBreakpoint}`}
            />
          </Col>
          <Col xs={6} md={6} lg={7} className="text-center mr-2">
            <GatsbyImage
              style={galleryImage2Styles[matchingBreakpoint]}
              image={getImage(teamBlockGrid.images[1])}
              alt={teamBlockGrid.images[1].title}
              width="100%"
              height="348x"
              className={`${matchingBreakpoint}`}
            />
            <Link
              variant="primary"
              to="/about-adhoc"
              className="btn btn-primary position-relative"
              style={{ marginTop: "-25px", zIndex: "10" }}
              onClick={() => {
                trackCustomEvent("click", {
                  category: "Button",
                  label: "adhoc networks team",
                });
              }}
            >
              » adhoc <span className="d-none d-md-inline-block">networks</span>{" "}
              {t("team")}
            </Link>
          </Col>
          <Col xs={6} md={6} lg={5}>
            <GatsbyImage
              style={galleryImage3Styles[matchingBreakpoint]}
              objectPosition="60% 50%"
              image={getImage(teamBlockGrid.images[2])}
              alt={teamBlockGrid.images[2].title}
              width="100%"
              height="500px"
              className={`${matchingBreakpoint}`}
            />
          </Col>
        </Row>
        <div className="orange-circle" />
      </Container>

      <Container className="mt-7">
        <h2 className="h1 mb-2 mb-md-5 ">{t("blog.latest.headline")}</h2>
        <Row xs={1} md={2} lg={3} className="g-4 mb-2">
          {data.allContentfulBlogPost.edges.map(({ node }) => (
            <BlogPostCard blogPost={node} key={node.id} />
          ))}
        </Row>
        <div className=" mt-5 d-flex">
          <Button
            variant="primary"
            href="/blog/"
            className="ff-sans text-uppercase"
          >
            {t("blog.nav.all")}
          </Button>
        </div>
      </Container>

      <Container className="mt-7">
        <Row xs={1} md={2}>
          <GatsbyImage
            alt={eushImage.title}
            image={getImage(eushImage.image)}
            className="bg-white rounded"
            imgClassName="p-4"
            objectFit="contain"
          />
          <div
            className="fw-light d-flex align-items-center p-5"
            dangerouslySetInnerHTML={{
              __html: eushText.body.childMarkdownRemark.html,
            }}
          />
        </Row>
      </Container>
    </Layout>
  );
};

export default IndexPage;

IndexPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    site: GatsbySitePropType,
    topNavigation: NavigationPropType,
    legalNavigation: NavigationPropType,
    linkNavigation: NavigationPropType,
    contentfulPage: ContentfulPagePropType,
    locales: LocalesPropType,
  }).isRequired,
};

export const query = graphql`
  query IndexPage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    legalNavigation: contentfulNavigation(
      slug: { eq: "legal-info" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    linkNavigation: contentfulNavigation(
      slug: { eq: "links" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    topNavigation: contentfulNavigation(
      slug: { eq: "top-navigation" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    topOffcanvasNavigation: contentfulNavigation(
      slug: { eq: "top-offcanvas" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    contentfulPage(slug: { eq: "/" }, node_locale: { eq: $language }) {
      ...ContentfulPageFragment
    }
    allContentfulBlogPost(
      filter: { node_locale: { eq: $language }, title: { ne: null } }
      sort: { order: DESC, fields: date }
      limit: 3
    ) {
      edges {
        node {
          id
          slug
          title
          createdAt(formatString: "DD.MM.YYYY")
          body {
            childMarkdownRemark {
              excerpt(format: PLAIN, truncate: true)
            }
          }
          category {
            name
            slug
          }
          image {
            id
            internal {
              type
            }
            title
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              height: 450
              breakpoints: [576, 768, 992, 1200]
              quality: 75
            )
          }
        }
      }
    }
    sensor: file(relativePath: { eq: "sensor_offen_hp.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    site {
      siteMetadata {
        siteUrl
        title
        env
      }
    }
  }
`;
