/* eslint-disable react/no-danger */
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PropTypes from "prop-types";
import * as React from "react";

const SectionIntroRow = function SectionIntro({
  contentElements,
  headlineClassName,
  className,
  cols,
}) {
  const headline = contentElements.filter((element) => {
    return element.internal.type === "ContentfulHeadline";
  })[0];

  const text = contentElements.filter((element) => {
    return element.internal.type === "ContentfulMarkdownText";
  })[0];

  return (
    <Row className={className}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Col {...cols}>
        {headline && (
          <span
            dangerouslySetInnerHTML={{
              __html: headline.text.childMarkdownRemark.html.replace(
                /<h2>/,
                `<h2 class=${headlineClassName}>`
              ),
            }}
          />
        )}

        {text && (
          <p
            className="lead"
            dangerouslySetInnerHTML={{
              __html: text.body.childMarkdownRemark.html.replace(
                /(<p>|<\/p>)/i,
                ""
              ),
            }}
          />
        )}
      </Col>
    </Row>
  );
};

SectionIntroRow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  contentElements: PropTypes.arrayOf(PropTypes.object).isRequired,
  headlineClassName: PropTypes.string,
  className: PropTypes.string,
  cols: PropTypes.shape({
    md: PropTypes.number,
  }),
};

SectionIntroRow.defaultProps = {
  headlineClassName: "",
  className: "",
  cols: { md: 12, lg: 9 },
};

export default SectionIntroRow;
