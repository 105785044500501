/* eslint-disable react/no-danger */
import * as React from "react";
import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../scss/App.scss";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { trackCustomEvent } from "../lib/TrackingHelper";
import Layout from "../components/Layout";
import whiteCircleUrl from "../images/circle-white.svg";
import { ReactComponent as IconLinkedIn } from "../images/icon-linkedin_alt.svg";
import { ReactComponent as IconEmail } from "../images/icon-email.svg";
import { getBlockBySlug, getElementByType } from "../lib/ContentfulHelper";

import {
  ContentfulPagePropType,
  GatsbySitePropType,
  LocalesPropType,
  NavigationPropType,
} from "../lib/PropTypeShapes";
import welleUrl from "../images/welle.svg";
import SectionIntroRow from "../components/SectionIntroRow";
import Icon from "../components/Icon";

const AboutPage = function AboutPage({ data, location }) {
  const { t } = useTranslation();
  const { contentBlocks } = data.contentfulPage;

  const intro = getBlockBySlug(contentBlocks, "intro-about");
  const introGrid = getBlockBySlug(contentBlocks, "about-intro-grid");
  const introImages = getElementByType(introGrid, "ContentfulImageGrid").images;
  const teamList = getBlockBySlug(contentBlocks, "team-list");
  const teamListLength = teamList.contentElements.length;
  const noBrainer = getBlockBySlug(contentBlocks, "no-brainer");
  const noBrainerImages = getElementByType(
    noBrainer,
    "ContentfulImageGrid"
  ).images;
  const noBrainerText = getElementByType(
    noBrainer,
    "ContentfulMarkdownText",
    1
  );
  const teamGrid = getBlockBySlug(contentBlocks, "team-grid");
  const teamGridImg = getElementByType(teamGrid, "ContentfulImageGrid").images;
  const teamGridText = getElementByType(teamGrid, "ContentfulMarkdownText");
  const teamGridButton = getElementByType(teamGrid, "ContentfulButton");

  const breakpoints = useBreakpoint();

  const [matchingBreakpoint, setMatchingBreakpoint] = useState({});

  useEffect(() => {
    if (Object.keys(breakpoints).length > 0) {
      const breakpointArray = Object.entries(breakpoints);
      const matches = breakpointArray.filter((breakpoint) => {
        return breakpoint[1];
      });
      setMatchingBreakpoint(matches[matches.length - 1][0]);
    }
  }, [breakpoints]);

  const introImageStyles1 = {
    xs: { width: "100%", height: "300px" },
    md: { width: "100%", height: "440px" },
    lg: { width: "100%", height: "658px" },
    xl: { width: "100%", height: "658px" },
  };

  const introImagePosition1 = {
    xs: "100% 50%",
    md: "65% 90%",
    lg: "50% 50%",
    xl: "50% 50%",
  };

  const introImageStyles2 = {
    xs: { width: "400px", height: "200px" },
    md: { width: "384px", height: "440px" },
    lg: { width: "910px", height: "658px" },
    xl: { width: "910px", height: "658px" },
  };

  const introImagePosition2 = {
    xs: "-20px 50%",
    md: "52% 10%",
    lg: "20% 90%",
    xl: "20% 90%",
  };

  const nobrainerImageStyles = {
    xs: { width: "700px", height: "440px" },
    md: { width: "974px", height: "440px" },
    lg: { width: "974px", height: "658px" },
    xl: { width: "974px", height: "658px" },
  };

  const nobrainerImagePosition = {
    xs: "140px 0%",
    md: "0 50%",
    lg: "0 50%",
    xl: "0 50%",
  };

  const galleryImage1Styles = {
    xs: { width: "100%", height: "250px" },
    md: { width: "100%", height: "489px" },
    lg: { width: "100%", height: "489px" },
    xl: { width: "100%", height: "489px" },
  };

  const galleryImage1Position = {
    xs: "50% 70%",
    md: "50% 70%",
    lg: "50% 70%",
    xl: "50% 70%",
  };

  const galleryImage2Styles = {
    xs: { width: "100%", height: "280px" },
    md: { width: "100%", height: "489px" },
    lg: { width: "100%", height: "489px" },
    xl: { width: "100%", height: "489px" },
  };

  const galleryImage2Position = {
    xs: "50% 50%",
    md: "50% 50%",
    lg: "50% 50%",
    xl: "50% 50%",
  };

  return (
    <Layout data={data} location={location}>
      <Container className="mb-4 mb-md-6">
        <SectionIntroRow contentElements={intro.contentElements} />
      </Container>

      <Container
        fluid
        className="hero-image about mb-4 mb-md-6 position-relative"
      >
        <Container className="position-relative">
          <Row>
            <Col xs={12} md={5} className="mb-2 mb-md-0">
              <GatsbyImage
                className={`team-hero-1 ${matchingBreakpoint}`}
                style={introImageStyles1[matchingBreakpoint]}
                objectPosition={introImagePosition1[matchingBreakpoint]}
                image={getImage(introImages[0])}
                alt={introImages[0].title}
              />
            </Col>
            <Col xs={12} md={7}>
              <GatsbyImage
                className={`team-hero-2 ${matchingBreakpoint}`}
                style={introImageStyles2[matchingBreakpoint]}
                objectPosition={introImagePosition2[matchingBreakpoint]}
                image={getImage(introImages[1])}
                alt={introImages[1].title}
              />
            </Col>
          </Row>
          <div
            className="white-circle-about"
            style={{
              background: `url(${whiteCircleUrl})`,
              position: "absolute",
            }}
          />
        </Container>
        <div
          style={{
            width: "100%",
            background: `url(${welleUrl})`,
            height: "102px",
            position: "absolute",
            top: "calc(50% - 102px/5)",
          }}
        />
      </Container>

      <Container className="mt-4">
        <SectionIntroRow contentElements={teamList.contentElements} />
        <Row className="mt-5 mt-lg-7 position-relative" style={{ zIndex: 10 }}>
          <Col
            md={{ span: 10, offset: 1 }}
            className="d-grid gap-5 d-flex flex-wrap justify-content-center justify-content-md-between"
          >
            {teamList.contentElements.map((element) => {
              if (element.internal.type !== "ContentfulPerson") {
                return null;
              }
              const image = getImage(element.image);
              return (
                <div
                  className="team-member d-flex flex-column align-items-center"
                  key={element.id}
                  style={{ maxWidth: "228px" }}
                >
                  <GatsbyImage
                    width={228}
                    height={228}
                    image={image}
                    alt={element.title}
                    className="rounded-circle"
                    imgClassName="rounded-circle"
                  />
                  <h3 className="mt-2 mb-1">{element.name}</h3>
                  <small>{element.title}</small>
                  <div className="d-flex mt-2">
                    {element.linkedIn && (
                      <a href={`${element.linkedIn}`}>
                        <IconLinkedIn height="16" className="me-2" />
                      </a>
                    )}
                    {element.email && (
                      <a href={`mailto:${element.email}`}>
                        <IconEmail height="16" />
                      </a>
                    )}
                  </div>
                </div>
              );
            })}
            {/* Render a Placeholder if list length is not even */}
            {teamListLength % 2 !== 0 && <div style={{ minWidth: "228px" }} />}
          </Col>
        </Row>
      </Container>

      <Container fluid className="bg-primary mt-5 mt-md-7 no-padding">
        <Container>
          <Row
            className=" d-flex d-md-none flex-column justify-content-center align-items-center pt-5 pb-2 pb-md-0 pt-md-0 "
            style={{ direction: "ltr", minHeight: "319px" }}
          >
            <div className="d-flex justify-content-center">
              <figure>
                <blockquote className="blockquote">
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        noBrainerText.body.childMarkdownRemark.html.replace(
                          /<h1>/,
                          "<h1 class='text-white'>"
                        ),
                    }}
                  />
                </blockquote>
                <figcaption className="blockquote-footer text-white mt-3 ff-sans fs-5 text-uppercase pt-3">
                  Ole Ostermann
                </figcaption>
              </figure>
            </div>
          </Row>
        </Container>
      </Container>
      <Container fluid className="ole no-padding">
        <Container
          fluid="lg"
          className="position-relative no-padding"
          style={{ direction: "rtl" }}
        >
          <Row>
            <Col
              md={{ span: 12 }}
              lg={{ span: 5 }}
              className="bg-primary d-none d-md-flex flex-column justify-content-center align-items-center pt-5 pb-2 pb-md-0 pt-md-0 "
              style={{ direction: "ltr", minHeight: "319px" }}
            >
              <div className="d-flex justify-content-center">
                <figure>
                  <blockquote className="blockquote">
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          noBrainerText.body.childMarkdownRemark.html.replace(
                            /<h1>/,
                            "<h1 class='text-white'>"
                          ),
                      }}
                    />
                  </blockquote>
                  <figcaption className="blockquote-footer text-white mt-3 ff-sans fs-5 text-uppercase pt-3">
                    Ole Ostermann
                  </figcaption>
                </figure>
              </div>
            </Col>
            <Col md={{ span: 12 }} lg={{ span: 7 }}>
              <GatsbyImage
                className={`no-brainer ${matchingBreakpoint}`}
                style={nobrainerImageStyles[matchingBreakpoint]}
                objectPosition={nobrainerImagePosition[matchingBreakpoint]}
                image={getImage(noBrainerImages[0])}
                alt={noBrainerImages[0].title}
              />
            </Col>
          </Row>
          <div
            className="white-circle-about2"
            style={{
              background: `url(${whiteCircleUrl})`,
              position: "absolute",
            }}
          />
        </Container>
      </Container>

      <Container className="mt-4">
        <SectionIntroRow contentElements={noBrainer.contentElements} />
      </Container>

      <Container className="mt-6 mt-lg-7">
        <Row>
          <Col md={12} className="mb-3">
            <GatsbyImage
              className={`team-gallery ${matchingBreakpoint}`}
              style={galleryImage1Styles[matchingBreakpoint]}
              objectPosition={galleryImage1Position[matchingBreakpoint]}
              image={getImage(teamGridImg[0])}
              alt={teamGridImg[0].title}
            />
          </Col>
          <Col md={6} lg={8} className="text-center mb-3 mb-md-0">
            <GatsbyImage
              className={`team-gallery ${matchingBreakpoint}`}
              style={galleryImage2Styles[matchingBreakpoint]}
              objectPosition={galleryImage2Position[matchingBreakpoint]}
              image={getImage(teamGridImg[1])}
              alt={teamGridImg[1].title}
            />
          </Col>
          <Col md={6} lg={4}>
            <div className="deine-chance ps-5 pe-4 py-5 bg-secondary h-100 position-relative flex-column fw-light">
              <span
                dangerouslySetInnerHTML={{
                  __html: teamGridText.body.childMarkdownRemark.html.replace(
                    /<h2>/,
                    "<h2 class='text-white small'>"
                  ),
                }}
              />
              <div className="d-flex align-items-center flex-column">
                <a
                  href={teamGridButton.externalLink}
                  className="btn btn-primary position-absolute me-2"
                  style={{ bottom: "-20px" }}
                  onClick={() => {
                    trackCustomEvent("click", {
                      category: "Button",
                      label: teamGridButton.buttonText,
                    });
                  }}
                >
                  {teamGridButton.buttonText}
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid="lg" className="cta mt-7 mt-md-5 mt-lg-7 d-none">
        <Row className="g-0 bg-white shadow-sm rounded-1">
          <Col xs={12} md={10}>
            <div className="px-4 ps-md-6 pe-md-6 pe-lg-7 pt-5 pt-lg-3 pb-4 position-relative">
              <h2 className="mt-0 mt-lg-3 mt-md-1 mb-1 small text-primary">
                {t("jobs.developer.title")}
              </h2>
              <p>{t("jobs.developer.description")}</p>
              <div className="position-absolute" style={{ bottom: "-20px" }}>
                <a
                  target="_blank"
                  href={t("jobs.developer.download-link")}
                  className="btn btn-primary"
                  rel="noreferrer"
                >
                  <Icon
                    className="me-2"
                    name="ArrowDown"
                    width="19px"
                    height="19px"
                    style={{
                      verticalAlign: "sub",
                      marginTop: "1px",
                    }}
                  />
                  {t("jobs.developer.download-title")}
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default AboutPage;

AboutPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    site: GatsbySitePropType,
    topNavigation: NavigationPropType,
    legalNavigation: NavigationPropType,
    linkNavigation: NavigationPropType,
    contentfulPage: ContentfulPagePropType,
    locales: LocalesPropType,
  }).isRequired,
};

export const query = graphql`
  query AboutPage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    legalNavigation: contentfulNavigation(
      slug: { eq: "legal-info" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    linkNavigation: contentfulNavigation(
      slug: { eq: "links" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    topNavigation: contentfulNavigation(
      slug: { eq: "top-navigation" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    topOffcanvasNavigation: contentfulNavigation(
      slug: { eq: "top-offcanvas" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    contentfulPage(
      slug: { eq: "about-adhoc" }
      node_locale: { eq: $language }
    ) {
      ...ContentfulPageFragment
    }
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
  }
`;
