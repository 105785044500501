import PropTypes from "prop-types";

const NavigationPropType = PropTypes.shape({
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}).isRequired;

const ContentfulPagePropType = PropTypes.shape({
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
}).isRequired;

const ContentfulBlogCategoryPropType = PropTypes.shape({
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}).isRequired;

const ContentfulBlogPostPropType = PropTypes.shape({
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({ html: PropTypes.string.isRequired }),
  }).isRequired,
}).isRequired;

const LocalesPropType = PropTypes.shape({
  edges: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        ns: PropTypes.string.isRequired,
        language: PropTypes.string.isRequired,
        data: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
}).isRequired;

const GatsbySitePropType = PropTypes.shape({
  siteMetadata: PropTypes.shape({
    title: PropTypes.string.isRequired,
    siteUrl: PropTypes.string.isRequired,
  }).isRequired,
}).isRequired;

const GatsbyLocationPropType = PropTypes.shape({
  pathname: PropTypes.string.isRequired,
}).isRequired;

export {
  NavigationPropType,
  ContentfulPagePropType,
  LocalesPropType,
  GatsbySitePropType,
  GatsbyLocationPropType,
  ContentfulBlogPostPropType,
  ContentfulBlogCategoryPropType,
};
