/* eslint-disable react/no-danger */
import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "../scss/App.scss";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import get from "lodash.get";
import { Helmet } from "react-helmet";
import {
  ContentfulBlogCategoryPropType,
  ContentfulBlogPostPropType,
  GatsbySitePropType,
  LocalesPropType,
  NavigationPropType,
} from "../lib/PropTypeShapes";
import Layout from "../components/Layout";
import BlogPostCard from "../components/BlogPostCard";
import BlogNav from "../components/BlogNav";
import BlogLatestPost from "../components/BlogLatestPost";

const BlogCategory = function BlogCategory({ data, location }) {
  const latestBlogPost = get(data, "allContentfulBlogPost.edges[0].node");

  if (!latestBlogPost) {
    return null;
  }

  return (
    <Layout data={data} location={location}>
      <Helmet />
      <Container as="section">
        {latestBlogPost && latestBlogPost.title && (
          <BlogLatestPost blogPost={latestBlogPost} />
        )}

        <BlogNav
          categories={data.allContentfulBlogCategory.edges}
          currentCategory={data.contentfulBlogCategory}
        />

        <Row xs={1} md={2} className="g-4 mb-2">
          {data.allContentfulBlogPost.edges.map(({ node }) => (
            <BlogPostCard blogPost={node} />
          ))}
        </Row>
      </Container>
    </Layout>
  );
};

export default BlogCategory;

BlogCategory.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    site: GatsbySitePropType,
    topNavigation: NavigationPropType,
    legalNavigation: NavigationPropType,
    linkNavigation: NavigationPropType,
    allContentfulBlogPost: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({ node: ContentfulBlogPostPropType })
      ),
    }),
    contentfulBlogCategory: ContentfulBlogCategoryPropType,
    allContentfulBlogCategory: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({ node: ContentfulBlogCategoryPropType })
      ),
    }),
    locales: LocalesPropType,
    legalDoc: PropTypes.shape({
      html: PropTypes.string,
    }),
  }).isRequired,
};

export const query = graphql`
  query BlogCategory($language: String!, $slug: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    legalNavigation: contentfulNavigation(
      slug: { eq: "legal-info" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    linkNavigation: contentfulNavigation(
      slug: { eq: "links" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    topNavigation: contentfulNavigation(
      slug: { eq: "top-navigation" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    topOffcanvasNavigation: contentfulNavigation(
      slug: { eq: "top-offcanvas" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    allContentfulBlogPost(
      filter: {
        category: { slug: { eq: $slug } }
        node_locale: { eq: $language }
        title: { ne: null }
      }
      sort: { order: DESC, fields: date }
    ) {
      edges {
        node {
          id
          slug
          title
          createdAt(formatString: "DD.MM.YYYY")
          body {
            childMarkdownRemark {
              excerpt(format: PLAIN, truncate: true)
              html
            }
          }
          category {
            name
            slug
          }
          image {
            id
            internal {
              type
            }
            title
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              height: 810
              breakpoints: [576, 768, 992, 1200]
              quality: 90
            )
          }
        }
      }
    }
    contentfulBlogCategory(
      slug: { eq: $slug }
      node_locale: { eq: $language }
    ) {
      ...ContentfulBlogCategoryFragment
    }
    allContentfulBlogCategory(filter: { node_locale: { eq: $language } }) {
      edges {
        node {
          id
          slug
          name
        }
      }
    }
    legalDoc(language: { eq: $language }, slug: { eq: $slug }) {
      id
      html
    }
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
  }
`;
