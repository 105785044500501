import * as React from "react";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import { useTranslation } from "gatsby-plugin-react-i18next";

const BlogPostCard = function BlogPostCard({ blogPost }) {
  const { t } = useTranslation();
  return (
    <Col>
      <Card className="border-0 shadow-sm h-100">
        <a href={`/blog/${blogPost.slug}`}>
          {blogPost.image && (
            <GatsbyImage
              className="card-img-top"
              image={getImage(blogPost.image)}
              alt={blogPost.image.title}
            />
          )}
        </a>

        <Card.Body className="p-4">
          <Card.Title as="h3" className="text-dark fw-light my-3 mt-0">
            <a href={`/blog/${blogPost.slug}`}>
              <small
                className="d-block mb-2"
                style={{ fontSize: "1rem", fontWeight: "300" }}
              >
                {blogPost.category && blogPost.category.name}
              </small>
              {blogPost.title}
            </a>
          </Card.Title>
          <Card.Text className="position-relative">
            {blogPost.body && blogPost.body.childMarkdownRemark.excerpt}

            <a
              href={`/blog/${blogPost.slug}`}
              className="d-block mt-3 stretched-link"
            >
              {t("blog.read-more")}
            </a>
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default BlogPostCard;

BlogPostCard.propTypes = {
  blogPost: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    image: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }),
    category: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    body: PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        excerpt: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
};
