/* eslint-disable react/no-danger */
import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Nav from "react-bootstrap/Nav";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "../scss/App.scss";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { GatsbySitePropType, LocalesPropType } from "../lib/PropTypeShapes";
import Icon from "../components/Icon";

const VcardTemplate = function VcardTemplate({ data }) {
  const { t } = useTranslation();
  return (
    <>
      <Container
        fluid
        className="d-flex justify-content-center bg-primary text-white"
      >
        <Helmet>
          <body className="vcard" />
        </Helmet>
        <Container
          style={{ maxWidth: "570px" }}
          className="mt-md-5 pt-5 shadow-lg px-0"
        >
          <Row
            className="text-center d-flex justify-content-center"
            style={{ maxWidth: "570px" }}
          >
            <div style={{ width: "115px", height: "115px" }}>
              <GatsbyImage
                className="rounded-circle mx-auto"
                imgClassName="rounded-circle"
                image={getImage(data.contentfulPerson.image)}
                alt={data.contentfulPerson.image.title}
              />
            </div>
            <h2 className="text-white mb-3">{data.contentfulPerson.name}</h2>
            <p className="mb-2">{data.contentfulPerson.title}</p>
          </Row>

          <Nav justify className="mt-2">
            {data.contentfulPerson.phone && (
              <Nav.Item>
                <Nav.Link
                  href={`tel:${data.contentfulPerson.phone}`}
                  className="text-white"
                >
                  <Icon
                    name="Phone"
                    width="18px"
                    height="18px"
                    className="mb-1"
                  />
                  <br />
                  Call
                </Nav.Link>
              </Nav.Item>
            )}

            {data.contentfulPerson.linkedIn && (
              <Nav.Item>
                <Nav.Link
                  href={`${data.contentfulPerson.linkedIn}`}
                  className="text-white"
                  target="_blank"
                >
                  <svg
                    style={{ width: "20px", height: "20px" }}
                    viewBox="0 0 24 24"
                    className="mb-1"
                  >
                    <path
                      fill="#adb5bd"
                      d="M19 3A2 2 0 0 1 21 5V19A2 2 0 0 1 19 21H5A2 2 0 0 1 3 19V5A2 2 0 0 1 5 3H19M18.5 18.5V13.2A3.26 3.26 0 0 0 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17A1.4 1.4 0 0 1 15.71 13.57V18.5H18.5M6.88 8.56A1.68 1.68 0 0 0 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19A1.69 1.69 0 0 0 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56M8.27 18.5V10.13H5.5V18.5H8.27Z"
                    />
                  </svg>
                  <br />
                  LinkedIn
                </Nav.Link>
              </Nav.Item>
            )}

            {data.contentfulPerson.email && (
              <Nav.Item>
                <Nav.Link
                  href={`mailto:${data.contentfulPerson.email}`}
                  className="text-white text-nowrap"
                >
                  <Icon
                    name="Email"
                    width="18px"
                    height="18px"
                    className="mb-1"
                  />
                  <br />
                  E-Mail
                </Nav.Link>
              </Nav.Item>
            )}

            <Nav.Item>
              <Nav.Link
                href="https://goo.gl/maps/at4MytQFbkoJzsfm7"
                className="text-white"
                target="_blank"
              >
                <svg
                  style={{ width: "24px", height: "24px" }}
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#ffffff"
                    d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z"
                  />
                </svg>
                <br />
                Location
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Container>
      </Container>

      <Container
        fluid
        className="pb-6 d-flex justify-content-center bg-body position-relative"
      >
        <Container
          style={{ maxWidth: "570px" }}
          className="vcard-details shadow-lg pt-5 pb-5 px-md-5"
        >
          <ul className="list-unstyled">
            {data.contentfulPerson.phone && (
              <>
                <li className="d-flex align-items-center">
                  <span
                    style={{ width: "2.2rem", marginRight: "2rem" }}
                    className="d-flex justify-content-center flex-shrink-0"
                  >
                    <Icon
                      name="Phone"
                      width="20px"
                      height="20px"
                      className="mb-1"
                    />
                  </span>
                  <p className="mb-0">
                    <a
                      className="link-dark"
                      href={`tel:${data.contentfulPerson.phone}`}
                      rel="noreferrer"
                    >
                      {data.contentfulPerson.phone}
                    </a>
                    <br />
                    <span className="text-muted">Telephone</span>
                  </p>
                </li>
                <hr />
              </>
            )}

            {data.contentfulPerson.email && (
              <>
                <li className="d-flex align-items-center">
                  <span
                    style={{ width: "2.2rem", marginRight: "2rem" }}
                    className="d-flex justify-content-center flex-shrink-0"
                  >
                    <Icon name="Email" width="20px" height="20px" />
                  </span>
                  <p className="mb-0">
                    <a
                      className="link-dark"
                      href={`mailto:${data.contentfulPerson.email}`}
                      rel="noreferrer"
                    >
                      {data.contentfulPerson.email}
                    </a>
                    <br />
                    <span className="text-muted">E-Mail</span>
                  </p>
                </li>
                <hr />
              </>
            )}

            <li className="d-flex align-items-center">
              <span
                style={{ width: "2.2rem", marginRight: "2rem" }}
                className="d-flex justify-content-center flex-shrink-0"
              >
                <svg
                  style={{ width: "24px", height: "24px" }}
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#adb5bd"
                    d="M10,2H14A2,2 0 0,1 16,4V6H20A2,2 0 0,1 22,8V19A2,2 0 0,1 20,21H4C2.89,21 2,20.1 2,19V8C2,6.89 2.89,6 4,6H8V4C8,2.89 8.89,2 10,2M14,6V4H10V6H14Z"
                  />
                </svg>
              </span>
              <p className="mb-0 text-dark">
                adhoc networks GmbH
                <br />
                <span className="text-muted">
                  {data.contentfulPerson.title}
                </span>
              </p>
            </li>
            <hr />

            <li className="d-flex align-items-center">
              <span
                style={{ width: "2.2rem", marginRight: "2rem" }}
                className="d-flex justify-content-center flex-shrink-0"
              >
                <svg
                  style={{ width: "24px", height: "24px" }}
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#adb5bd"
                    d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z"
                  />
                </svg>
              </span>
              <p className="mb-0 text-dark">
                Oststraße 52
                <br />
                22844 Norderstedt
                <br />
                Germany
                <br />
                <a
                  href="https://goo.gl/maps/at4MytQFbkoJzsfm7"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("vcard.openMap")}
                </a>
              </p>
            </li>
            <hr />

            <li className="">
              <a
                href={`/vcards/${data.contentfulPerson.slug}.vcf`}
                className="btn btn-primary download d-flex d-md-block justify-content-center align-items-center"
              >
                <svg
                  className="d-block d-md-none"
                  style={{ width: "24px", height: "24px" }}
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#ffffff"
                    d="M15,14C12.33,14 7,15.33 7,18V20H23V18C23,15.33 17.67,14 15,14M6,10V7H4V10H1V12H4V15H6V12H9V10M15,12A4,4 0 0,0 19,8A4,4 0 0,0 15,4A4,4 0 0,0 11,8A4,4 0 0,0 15,12Z"
                  />
                </svg>
                <span className="d-none d-md-block">Download vCard</span>
              </a>
            </li>
          </ul>
        </Container>
      </Container>
    </>
  );
};

export default VcardTemplate;

VcardTemplate.propTypes = {
  data: PropTypes.shape({
    site: GatsbySitePropType,
    locales: LocalesPropType,
    contentfulPerson: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      linkedIn: PropTypes.string.isRequired,
      image: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query VcardTemplate($language: String!, $slug: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    contentfulPerson(slug: { eq: $slug }, node_locale: { eq: $language }) {
      name
      slug
      title
      email
      phone
      linkedIn
      image {
        title
        gatsbyImageData(
          resizingBehavior: FILL
          width: 200
          height: 200
          layout: CONSTRAINED
          quality: 100
          cropFocus: FACE
          placeholder: BLURRED
        )
        id
      }
    }
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
  }
`;
