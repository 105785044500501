import * as React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { useI18next } from "gatsby-plugin-react-i18next";

const SEO = function SEO({ title, description, siteMetadata }) {
  const { languages, originalPath, language } = useI18next();
  const otherLanguages = languages.filter((lng) => {
    return lng !== language;
  });

  return (
    <Helmet>
      <html lang={language.split("-")[0]} />
      <title>
        {title} | {siteMetadata.title}
      </title>
      {description && <meta name="description" content={description} />}

      {otherLanguages.map((lng) => (
        <link
          key={`rel-alternate-${lng}`}
          rel="alternate"
          hrefLang={lng.split("-")[0]}
          href={`${siteMetadata.siteUrl}/${lng}${originalPath}`}
        />
      ))}

      <link
        rel="alternate"
        hrefLang="x-default"
        href={`${siteMetadata.siteUrl}${originalPath}`}
      />
    </Helmet>
  );
};

export default SEO;

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  // Todo Global siteproptype failed here so retry to add
  siteMetadata: PropTypes.shape({
    title: PropTypes.string.isRequired,
    siteUrl: PropTypes.string.isRequired,
  }).isRequired,
};

SEO.defaultProps = {
  description: null,
};
