import React from "react";
import { Link } from "gatsby-plugin-react-i18next";
import PropTypes from "prop-types";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Offcanvas from "react-bootstrap/Offcanvas";
import Container from "react-bootstrap/Container";
import { ReactComponent as Logo } from "../images/adhoc-logo.svg";
import LanguageSwitcher from "./LanguageSwitch";
import ThemeSwitch from "./ThemeSwitch";

const Header = function Header({
  navigation,
  offcanvasNavigation,
  legalNavigation,
  theme,
}) {
  return (
    <Container>
      <Navbar variant={theme} className="mt-4 mt-lg-5 mb-6 mb-lg-8" expand="lg">
        <Navbar.Brand as={Link} to="/">
          <Logo className="logo" width={234} height={73} />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="offcanvasNavbar"
          className="border-0 mt-2 mt-lg-4"
        />

        <Navbar.Collapse
          id="basic-navbar-nav"
          className="d-none"
          style={{ marginTop: "36px" }}
        >
          <Nav className="ms-auto me-4">
            {navigation.map((navigate) => (
              <Nav.Link
                key={navigate.id}
                as={Link}
                to={`/${navigate.slug.replace(/^\//, "")}`}
                activeClassName="active"
              >
                {navigate.title}
              </Nav.Link>
            ))}
          </Nav>
          <LanguageSwitcher className="me-4" location="header" />
          <ThemeSwitch />
        </Navbar.Collapse>

        <Navbar.Offcanvas
          className="d-lg-none"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="end"
          backdrop
        >
          <Offcanvas.Header closeButton>
            <span />
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="lh-lg">
              {offcanvasNavigation.map((navigate) => (
                <Nav.Link
                  key={navigate.id}
                  as={Link}
                  to={`/${navigate.slug.replace(/^\//, "")}`}
                  className=""
                >
                  {navigate.title}
                </Nav.Link>
              ))}
            </Nav>
            <div className="d-flex mt-5">
              <LanguageSwitcher className="me-4" location="header" />
              <ThemeSwitch />
            </div>

            <ul className="list-inline text-muted mt-4">
              {legalNavigation &&
                legalNavigation.elements.map((item) => (
                  <li className="list-inline-item me-4" key={item.id}>
                    <Link to={`/${item.slug}`}>{item.title}</Link>
                  </li>
                ))}
            </ul>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Navbar>
    </Container>
  );
};
Header.defaultProps = {
  theme: "light",
};

Header.propTypes = {
  theme: PropTypes.string,
  navigation: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  offcanvasNavigation: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  legalNavigation: PropTypes.shape({
    elements: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
};

export default Header;
