/* eslint-disable react/no-danger */
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../scss/App.scss";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../components/Layout";
import ContactForm from "../components/ContactForm";
import aerialShot from "../images/adhoc-gebaeude-zentrale.jpg";

import {
  ContentfulPagePropType,
  GatsbySitePropType,
  LocalesPropType,
  NavigationPropType,
} from "../lib/PropTypeShapes";

const ContactPage = function ContactPage({ data, location }) {
  const { contentBlocks } = data.contentfulPage;
  const { t } = useTranslation();

  // ContentBlock Intro
  const intro = contentBlocks.filter((block) => {
    return block.slug === "contact-info";
  })[0].contentElements;

  return (
    <Layout
      data={data}
      location={location}
      className="contact"
      footerClassName="mt-0"
    >
      <Container className="mb-6">
        <Row>
          <Col md={9}>
            <span
              dangerouslySetInnerHTML={{
                __html: intro[0].text.childMarkdownRemark.html,
              }}
            />
          </Col>
        </Row>
      </Container>

      <Container
        fluid
        className="bg-white bg-darkmode-petrol-light pt-6 pt-lg-7 pb-md0 pb-lg-6 position-relative mb-lg-8"
        style={{ maxWidth: "1250px" }}
      >
        <Container className="ff-sans">
          <Row>
            <Col md={4} lg={2}>
              <h3 className="mb-2 mb-md-4">{t("contact.visit")}</h3>
              <p className="fw-light">
                adhoc networks GmbH
                <br />
                Oststr. 52 <br />
                22844 Norderstedt
              </p>
            </Col>

            <Col
              className="mt-3 mt-md-0"
              md={{ span: 4, offset: 1 }}
              lg={{ span: 2, offset: 1 }}
            >
              <h3 className="mb-2 mb-md-4">{t("contact.call")}</h3>
              <p className="fw-light">+49 (0) 40 60 06 01 60</p>
            </Col>

            <Col
              className="mt-3 mt-md-3 mt-lg-0"
              md={{ span: 12, offset: 0 }}
              lg={{ span: 6, offset: 1 }}
            >
              <h3 className="mb-4">{t("contact.write")}</h3>

              <ContactForm />
            </Col>
          </Row>
        </Container>
        <Row className="mt-5 mt-md-6 mt-lg-7 justify-content-center position-relative">
          {typeof document !== `undefined` &&
          document.cookie.includes("gatsby-gdpr-google-analytics=true") ? (
            <LoadScript googleMapsApiKey="AIzaSyD0laI-KFDetBS5cRriT3Q02bjTctyz3Eg">
              <GoogleMap
                options={{
                  disableDefaultUI: true,
                  styles: [
                    {
                      featureType: "poi",
                      elementType: "labels",
                      stylers: [
                        {
                          visibility: "off",
                        },
                      ],
                    },
                  ],
                }}
                clickableIcons={false}
                mapContainerStyle={{
                  height: "550px",
                  width: "100%",
                  maxWidth: "1120px",
                }}
                zoom={15}
                center={{ lat: 53.728442, lng: 10.0245953 }}
              >
                <Marker position={{ lat: 53.728442, lng: 10.0245953 }} />
              </GoogleMap>
            </LoadScript>
          ) : (
            <div>
              <div className="static-map">
                <img
                  alt="Karte"
                  src="https://maps.googleapis.com/maps/api/staticmap?size=1120x550&scale=2&center=53.735442,10.0245953&zoom=12&markers=53.728442,10.0245953&key=AIzaSyD0laI-KFDetBS5cRriT3Q02bjTctyz3Eg"
                />
              </div>
            </div>
          )}
          <div className="contact-speechbubble ff-sans text-white rounded-1 position-absolute">
            <div className="d-flex" style={{ width: `${248 * 2}px` }}>
              <img
                className="d-none d-md-block"
                height="171px"
                width="248px"
                src={aerialShot}
                alt="Gebäude"
              />
              <div
                className="bg-primary"
                style={{ width: "248px", padding: "25px 20px" }}
              >
                <small className="text-white">
                  <p>
                    adhoc networks GmbH
                    <br />
                    Oststraße 52
                    <br />
                    22844 Norderstedt
                  </p>

                  <p>
                    +49 (0) 40 60 06 01 60
                    <br />
                    <a href="mailto:info@ad-hoc.com" className="text-white">
                      info@ad-hoc.com
                    </a>
                  </p>
                </small>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </Layout>
  );
};

export default ContactPage;

ContactPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    site: GatsbySitePropType,
    topNavigation: NavigationPropType,
    legalNavigation: NavigationPropType,
    linkNavigation: NavigationPropType,
    contentfulPage: ContentfulPagePropType,
    locales: LocalesPropType,
  }).isRequired,
};

export const query = graphql`
  query ContactPage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    legalNavigation: contentfulNavigation(
      slug: { eq: "legal-info" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    linkNavigation: contentfulNavigation(
      slug: { eq: "links" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    topNavigation: contentfulNavigation(
      slug: { eq: "top-navigation" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    topOffcanvasNavigation: contentfulNavigation(
      slug: { eq: "top-offcanvas" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    contentfulPage(slug: { eq: "contact" }, node_locale: { eq: $language }) {
      ...ContentfulPageFragment
    }
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
  }
`;
