const getBlockBySlug = (blocks, slug) => {
  return blocks.filter((block) => block.slug === slug)[0];
};

const getElementByType = (block, type, index = 0) => {
  return block.contentElements.filter(
    (element) => element.internal.type === type
  )[index];
};

export { getBlockBySlug, getElementByType };
