import { Link } from "gatsby-plugin-react-i18next";
import * as React from "react";
import PropTypes from "prop-types";

const VerticalNavigation = function FooterNavigation({ navigation }) {
  return (
    <>
      <h6 className="d-block mb-2 fw-medium">{navigation.title}</h6>
      <ul className="lh-lg list-unstyled">
        {navigation.elements.map((item) => (
          <li key={item.id}>
            <Link to={`/${item.slug.replace(/^\//, "")}`}>{item.title}</Link>
          </li>
        ))}
      </ul>
    </>
  );
};

VerticalNavigation.propTypes = {
  navigation: PropTypes.shape({
    title: PropTypes.string.isRequired,
    elements: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
};

export default VerticalNavigation;
