/* eslint-disable react/no-danger */
import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../scss/App.scss";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Layout from "../components/Layout";

import {
  ContentfulPagePropType,
  GatsbyLocationPropType,
  GatsbySitePropType,
  LocalesPropType,
  NavigationPropType,
} from "../lib/PropTypeShapes";

const PageTemplate = function PageTemplate({ data, location }) {
  return (
    <Layout data={data} location={location}>
      <Container className="mb-5 legal-doc">
        <Row>
          <Col md={9}>
            <h1 className="mb-4">{data.contentfulPage.title}</h1>
            <span
              dangerouslySetInnerHTML={{
                __html: data.legalDoc.html,
              }}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default PageTemplate;

PageTemplate.propTypes = {
  location: GatsbyLocationPropType.isRequired,
  data: PropTypes.shape({
    site: GatsbySitePropType,
    topNavigation: NavigationPropType,
    legalNavigation: NavigationPropType,
    linkNavigation: NavigationPropType,
    contentfulPage: ContentfulPagePropType,
    locales: LocalesPropType,
    legalDoc: PropTypes.shape({
      html: PropTypes.string,
    }),
  }).isRequired,
};

export const query = graphql`
  query PageTemplate($language: String!, $slug: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    legalNavigation: contentfulNavigation(
      slug: { eq: "legal-info" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    linkNavigation: contentfulNavigation(
      slug: { eq: "links" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    topNavigation: contentfulNavigation(
      slug: { eq: "top-navigation" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    topOffcanvasNavigation: contentfulNavigation(
      slug: { eq: "top-offcanvas" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    contentfulPage(slug: { eq: $slug }, node_locale: { eq: $language }) {
      ...ContentfulPageFragment
      contentBlocks {
        id
        title
        contentElements {
          ... on ContentfulText {
            id
            body {
              raw
            }
          }
        }
      }
    }
    legalDoc(language: { eq: $language }, slug: { eq: $slug }) {
      id
      html
    }
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
  }
`;
