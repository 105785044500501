import * as React from "react";
import Col from "react-bootstrap/Col";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

const BlogLatestPost = function BlogLatestPost({ blogPost }) {
  const { t } = useTranslation();
  return (
    <Row>
      <Col sm={12}>
        <a href={`/blog/${blogPost.slug}`}>
          {blogPost.image && (
            <GatsbyImage
              className="card-img-top mb-4"
              image={getImage(blogPost.image)}
              alt={blogPost.image.title}
            />
          )}
        </a>
      </Col>
      <Col md="10" className="mb-7">
        <div className="position-relative">
          <h1>
            <mark>{t("blog.new")}: </mark>
            {blogPost.title}
          </h1>
          <p className="lead my-3">
            {blogPost.body.childMarkdownRemark.excerpt}
          </p>

          <p className="lead mb-0">
            <Button
              href={`/blog/${blogPost.slug}`}
              className="fw-bold stretched-link"
            >
              {t("blog.read-more")}
            </Button>
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default BlogLatestPost;

BlogLatestPost.propTypes = {
  blogPost: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    image: PropTypes.shape({
      title: PropTypes.string.isRequired,
      fluid: PropTypes.shape({
        src: PropTypes.string.isRequired,
      }),
    }),
    body: PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        excerpt: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
};
