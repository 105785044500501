import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as React from "react";
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import cn from "classnames";
import { trackCustomEvent } from "../lib/TrackingHelper";
import { ReactComponent as Logo } from "../images/adhoc-logo.svg";
import { ReactComponent as LinkedIn } from "../images/icon-linkedin.svg";
import VerticalNavigation from "./VerticalNavigation";

const Footer = function Footer({ legalNavigation, linkNavigation, className }) {
  const { languages, originalPath, language } = useI18next();
  const { t } = useTranslation();
  return (
    <footer className={`${className || "mt-7 mt-md-8"}`}>
      <Container fluid>
        <Container className="pt-5 pt-lg-7 pb-3 pb-md-6">
          <Row className="justify-content-between">
            <Col md={6} lg={4}>
              <Logo width={175} height={55} className="logo mb-4" />
              <p>{t("footer.slogan")}</p>
              +49 (0) 40 60 06 01 60
              <br />
              <a href="mailto:info@ad-hoc.com">info@ad-hoc.com</a>
              <br />
              <div className="d-none d-md-block mt-3">
                {languages.map((lng) => (
                  <Button
                    size="sm"
                    variant="secondary"
                    key={`footer-language-btn-${lng}`}
                    to={originalPath}
                    language={lng}
                    as={Link}
                    disabled={lng === language}
                    className={cn("me-2 text-uppercase bg-darkmode-white", {
                      disabled: lng === language,
                    })}
                    onClick={() => {
                      trackCustomEvent("change_language", {
                        selected_language: lng,
                        element_location: "footer",
                      });
                    }}
                  >
                    {/* i18next-extract-disable-next-line */}
                    {t(`language-name-${lng}`)}
                  </Button>
                ))}
              </div>
            </Col>

            <Col md={4} lg={4}>
              <Row xs={1} lg={2}>
                <Col className="mt-4 mt-md-3">
                  <VerticalNavigation navigation={legalNavigation} />
                </Col>

                <Col className="mt-2 mt-md-3">
                  <VerticalNavigation navigation={linkNavigation} />
                </Col>
              </Row>
            </Col>

            <div className="d-md-none mt-6 d-flex">
              {languages.map((lng) => (
                <Button
                  size="sm"
                  variant="secondary"
                  key={`footer-language-btn-${lng}`}
                  to={originalPath}
                  language={lng}
                  as={Link}
                  disabled={lng === language}
                  className={cn("me-2 text-uppercase", {
                    disabled: lng === language,
                  })}
                  onClick={() => {
                    trackCustomEvent("change_language", {
                      selected_language: lng,
                      element_location: "footer",
                    });
                  }}
                >
                  {/* i18next-extract-disable-next-line */}
                  {t(`language-name-${lng}`)}
                </Button>
              ))}
              <a
                target="_blank"
                href="https://www.linkedin.com/company/adhoc-networks-gmbh/"
                rel="noreferrer"
                className="ms-auto"
              >
                <LinkedIn width={33} heigt={33} />
              </a>
            </div>
          </Row>
        </Container>
      </Container>
      <Container fluid className="bg-white py-3">
        <Container>
          <div className="d-flex justify-content-between align-items-center">
            <a
              target="_blank"
              href="https://www.linkedin.com/company/adhoc-networks-gmbh/"
              rel="noreferrer"
              className="d-none d-md-block"
            >
              <LinkedIn width={38} heigt={38} />
            </a>
            <small className="text-muted">
              © {new Date().getFullYear()} adhoc networks | Design & Concept by{" "}
              <a href="https://www.40kts.de" target="_blank" rel="noreferrer">
                40knots
              </a>
            </small>
          </div>
        </Container>
      </Container>
    </footer>
  );
};

Footer.defaultProps = {
  className: "",
};

Footer.propTypes = {
  className: PropTypes.string,
  legalNavigation: PropTypes.shape({
    title: PropTypes.string.isRequired,
    elements: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
  linkNavigation: PropTypes.shape({
    title: PropTypes.string.isRequired,
    elements: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
};

export default Footer;
