import * as React from "react";
import Container from "react-bootstrap/Container";
import "../scss/App.scss";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { useEffect, useState } from "react";
import Layout from "../components/Layout";
import welleUrl from "../images/welle.svg";
import SectionIntroRow from "../components/SectionIntroRow";
import { getBlockBySlug } from "../lib/ContentfulHelper";
import {
  ContentfulPagePropType,
  GatsbyLocationPropType,
  GatsbySitePropType,
  LocalesPropType,
  NavigationPropType,
} from "../lib/PropTypeShapes";

const NotFoundPage = function NotFoundPage({ data, location }) {
  const { contentBlocks } = data.contentfulPage;
  const intro = getBlockBySlug(contentBlocks, "404-intro");
  const imageBlock = getBlockBySlug(contentBlocks, "404-image")
    .contentElements[0];
  const breakpoints = useBreakpoint();

  const heroImageStyles = {
    xs: { width: "780px", height: "380px", direction: "ltr" },
    md: { width: "1154px", height: "440px" },
    lg: { width: "1381px", height: "658px" },
    xl: { width: "1381px", height: "658px" },
  };

  const heroImagePosition = {
    xs: "90px 80%",
    md: "50% 75%",
    lg: "10% 90%",
    xl: "10% 90%",
  };

  const [matchingBreakpoint, setMatchingBreakpoint] = useState({});

  useEffect(() => {
    if (Object.keys(breakpoints).length > 0) {
      const breakpointArray = Object.entries(breakpoints);
      const matches = breakpointArray.filter((breakpoint) => {
        return breakpoint[1];
      });
      setMatchingBreakpoint(matches[matches.length - 1][0]);
    }
  }, [breakpoints]);

  return (
    <Layout data={data} location={location}>
      <Container as="section" className="mb-5">
        <SectionIntroRow contentElements={intro.contentElements} />
      </Container>

      <Container
        fluid
        as="section"
        className="hero-image mb-6 position-relative"
      >
        <Container
          className="mb-6 "
          style={{
            direction: imageBlock.align === "right" ? "rtl" : "ltr",
          }}
        >
          <GatsbyImage
            className={`index-hero ${matchingBreakpoint}`}
            style={heroImageStyles[matchingBreakpoint]}
            objectPosition={heroImagePosition[matchingBreakpoint]}
            image={getImage(imageBlock.image)}
            alt={imageBlock.image.title}
          />
        </Container>
        <div
          style={{
            width: "100%",
            background: `url(${welleUrl})`,
            height: "102px",
            position: "absolute",
            top: "calc(50% - 102px/5)",
          }}
        />
      </Container>
    </Layout>
  );
};

export default NotFoundPage;

NotFoundPage.propTypes = {
  location: GatsbyLocationPropType.isRequired,
  data: PropTypes.shape({
    site: GatsbySitePropType,
    topNavigation: NavigationPropType,
    legalNavigation: NavigationPropType,
    linkNavigation: NavigationPropType,
    contentfulPage: ContentfulPagePropType,
    locales: LocalesPropType,
  }).isRequired,
};

export const query = graphql`
  query NotFoundPage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    legalNavigation: contentfulNavigation(
      slug: { eq: "legal-info" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    linkNavigation: contentfulNavigation(
      slug: { eq: "links" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    topNavigation: contentfulNavigation(
      slug: { eq: "top-navigation" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    topOffcanvasNavigation: contentfulNavigation(
      slug: { eq: "top-offcanvas" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    contentfulPage(
      slug: { eq: "page-not-found" }
      node_locale: { eq: $language }
    ) {
      ...ContentfulPageFragment
    }
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
  }
`;
