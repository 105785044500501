/* eslint-disable react/no-danger */
import * as React from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Alert from "react-bootstrap/Alert";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { trackCustomEvent } from "../lib/TrackingHelper";

const RequestTestpackageModal = function RequestTestpackageModal({
  className,
  showModal,
  handleCloseModal,
  content,
}) {
  const schema = Yup.object().shape({
    company: Yup.string().required(),
    fullname: Yup.string().required(),
    email: Yup.string().required(),
    phone: Yup.string().required(),
    street: Yup.string().required(),
    city: Yup.string().required(),
    privacyPolicy: Yup.bool().oneOf([true], "Privacy Policy"),
    website: Yup.string(),
  });
  const { t } = useTranslation();

  const privacyPolicy = (
    <Trans i18nKey="order.form.privacy_policy">
      Ich habe die{" "}
      <a href="/privacy-policy" target="_blank">
        Datenschutzerklärung
      </a>{" "}
      gelesen und bin mit der Verarbeitung und Speicherung meiner Daten und der
      weiteren Kontaktaufnahme einverstanden. Diese Einwilligung kann jederzeit
      für die Zukunft per E-Mail an{" "}
      <a href="mailto:info@ad-hoc.com">info@ad-hoc.com</a> widerrufen werden.
    </Trans>
  );

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      backdrop="static"
      keyboard={false}
      size="lg"
      className={`order-modal ${className}`}
    >
      <Modal.Header closeButton className="border-0 mb-0 pb-0" />
      <Modal.Body className="pt-0">
        <Container>
          <Row>
            <Col>
              <span
                className="markdown"
                dangerouslySetInnerHTML={{
                  __html:
                    content.contentElements[0].text.childMarkdownRemark.html,
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <span
                className="markdown"
                dangerouslySetInnerHTML={{
                  __html:
                    content.contentElements[1].body.childMarkdownRemark.html.replace(
                      /<p>/,
                      "<p class='lead'>"
                    ),
                }}
              />
            </Col>
          </Row>
          <Row xs={1} md={2} className="mt-3">
            <Col>
              <span
                className="markdown"
                dangerouslySetInnerHTML={{
                  __html:
                    content.contentElements[2].body.childMarkdownRemark.html,
                }}
              />
            </Col>
            <Col>
              <span
                className="markdown"
                dangerouslySetInnerHTML={{
                  __html:
                    content.contentElements[3].body.childMarkdownRemark.html,
                }}
              />
            </Col>
          </Row>

          <Row className="mt-3">
            <h3>{t("order.form.headline")}</h3>
          </Row>

          <Formik
            initialValues={{
              company: "",
              fullname: "",
              email: "",
              phone: "",
              street: "",
              city: "",
              privacyPolicy: false,
              website: "",
            }}
            validationSchema={schema}
            onSubmit={async (
              values,
              { resetForm, setStatus, setSubmitting }
            ) => {
              const newValues = { source: "order", ...values };
              const formData = new FormData();

              const keys = Object.keys(newValues);
              keys.forEach((key) => {
                formData.append(key, newValues[key]);
              });
              axios
                .post("/send.php", formData, {
                  headers: { Accept: "application/json" },
                })
                .then(() => {
                  resetForm();
                  setStatus({ success: true });
                  setSubmitting(false);
                  // resetForm()
                })
                .catch(() => {
                  setStatus({ success: false });
                  setSubmitting(false);
                });

              trackCustomEvent("submit_form", {
                form: "testpackage",
              });
              setSubmitting(false);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              touched,
              errors,
              isSubmitting,
              status,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                {status && status.success && (
                  <Alert variant="primary">{t("order.form.thank_you")}</Alert>
                )}
                {status && !status.success && (
                  <Alert variant="danger">
                    Leider ist ein Fehler aufgetreten. Bitte senden Sie für Ihre
                    Anfrage eine E-Mail an{" "}
                    <a className="text-white" href="mailto:info@ad-hoc.com">
                      info@ad-hoc.com
                    </a>{" "}
                    .
                  </Alert>
                )}
                <Row xs={1} md={2}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicCompany">
                      <Form.Label>{`${t("order.form.company")}*`}</Form.Label>
                      <Form.Control
                        type="text"
                        name="company"
                        value={values.company}
                        onChange={handleChange}
                        isValid={touched.company && !errors.company}
                        isInvalid={!!errors.company}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>{`${t("order.form.fullname")}*`}</Form.Label>
                      <Form.Control
                        type="text"
                        name="fullname"
                        value={values.fullname}
                        onChange={handleChange}
                        isValid={touched.fullname && !errors.fullname}
                        isInvalid={!!errors.fullname}
                        required
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>{`${t("order.form.email")}*`}</Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        isValid={touched.email && !errors.email}
                        isInvalid={!!errors.email}
                        required
                      />
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                      controlId="formBasicWebsite"
                      style={{ display: "none" }}
                    >
                      <Form.Label>{`${t("order.form.website")}*`}</Form.Label>
                      <Form.Control
                        type="text"
                        name="website"
                        value={values.website}
                        onChange={handleChange}
                        isValid={touched.website && !errors.website}
                        isInvalid={!!errors.website}
                        tabIndex="-1"
                        autoComplete="off"
                        required
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicPhone">
                      <Form.Label>{`${t("order.form.phone")}*`}</Form.Label>
                      <Form.Control
                        type="text"
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                        isValid={touched.phone && !errors.phone}
                        isInvalid={!!errors.phone}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicStreet">
                      <Form.Label>{`${t("order.form.street")}*`}</Form.Label>
                      <Form.Control
                        type="text"
                        name="street"
                        value={values.street}
                        onChange={handleChange}
                        isValid={touched.street && !errors.street}
                        isInvalid={!!errors.street}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCity">
                      <Form.Label>{`${t("order.form.city")}*`}</Form.Label>
                      <Form.Control
                        type="text"
                        name="city"
                        value={values.city}
                        onChange={handleChange}
                        isValid={touched.city && !errors.city}
                        isInvalid={!!errors.city}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row xs={1} md={2}>
                  <p>{`${t("required_field")}`}</p>
                  <Col>
                    <Form.Check
                      type="checkbox"
                      name="privacyPolicy"
                      value={values.privacyPolicy}
                      onChange={handleChange}
                      isValid={touched.privacyPolicy && !errors.privacyPolicy}
                      isInvalid={!!errors.privacyPolicy}
                      required
                      id="default-checkbox"
                      className="fw-light"
                      label={privacyPolicy}
                    />
                  </Col>
                  <Col className="d-flex d-md-inline-block justify-content-center mt-3 mt-md-0">
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                      className="mt-2"
                    >
                      {t("order.form.submit")}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
RequestTestpackageModal.defaultProps = {
  className: "",
};

RequestTestpackageModal.propTypes = {
  className: PropTypes.string,
  showModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  content: PropTypes.object.isRequired,
};

export default RequestTestpackageModal;
