/* eslint-disable react/no-danger */
import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../scss/App.scss";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../components/Layout";

import {
  ContentfulBlogPostPropType,
  GatsbySitePropType,
  LocalesPropType,
  NavigationPropType,
} from "../lib/PropTypeShapes";

const BlogPost = function BlogPost({ data, location }) {
  const { t } = useTranslation();
  const currentURL = data.site.siteMetadata.siteUrl + location.pathname;
  const linkedInURL = `https://www.linkedin.com/sharing/share-offsite/?url=${currentURL}`;
  const { contentfulBlogPost } = data;
  const { category, title, image, abstract, body } = contentfulBlogPost;

  return (
    <Layout data={data} location={location}>
      <Container className="blog-post mb-5 legal-doc">
        <Row>
          <h1 className="mb-6">
            <small
              className="d-block mb-2"
              style={{ fontSize: "1rem", fontWeight: "300" }}
            >
              <Link to="/blog/">Blog</Link> &gt;{" "}
              {category && (
                <Link to={`/blog/${category.slug}/`}>{category.name}</Link>
              )}
            </small>
            {title}
          </h1>
          {image && (
            <GatsbyImage
              className="mb-4 px-0"
              image={getImage(image)}
              alt={image.title}
            />
          )}
        </Row>
        <Row>
          <Col md="12" lg="9">
            {abstract && (
              <span
                className="lead"
                dangerouslySetInnerHTML={{
                  __html: abstract.childMarkdownRemark.html,
                }}
              />
            )}

            {body && (
              <span
                className="markdown"
                dangerouslySetInnerHTML={{
                  __html: body.childMarkdownRemark.html,
                }}
              />
            )}
            <hr className="mt-6" />
            <a
              href={`mailto:?body=${currentURL}&subject=${title}`}
              className="me-3 ff-sans d-block d-sm-inline-block"
            >
              <svg
                style={{ width: "24px", height: "24px" }}
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z"
                />
              </svg>{" "}
              {t("blog.share.email")}
            </a>
            <a href={linkedInURL} className="ff-sans">
              <svg
                style={{ width: "24px", height: "24px" }}
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M19 3A2 2 0 0 1 21 5V19A2 2 0 0 1 19 21H5A2 2 0 0 1 3 19V5A2 2 0 0 1 5 3H19M18.5 18.5V13.2A3.26 3.26 0 0 0 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17A1.4 1.4 0 0 1 15.71 13.57V18.5H18.5M6.88 8.56A1.68 1.68 0 0 0 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19A1.69 1.69 0 0 0 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56M8.27 18.5V10.13H5.5V18.5H8.27Z"
                />
              </svg>{" "}
              {t("blog.share.linkedin")}
            </a>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default BlogPost;

BlogPost.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    site: GatsbySitePropType,
    topNavigation: NavigationPropType,
    legalNavigation: NavigationPropType,
    linkNavigation: NavigationPropType,
    contentfulBlogPost: ContentfulBlogPostPropType,
    locales: LocalesPropType,
    legalDoc: PropTypes.shape({
      html: PropTypes.string,
    }),
  }).isRequired,
};

export const query = graphql`
  query BlogPost($language: String!, $slug: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    legalNavigation: contentfulNavigation(
      slug: { eq: "legal-info" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    linkNavigation: contentfulNavigation(
      slug: { eq: "links" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    topNavigation: contentfulNavigation(
      slug: { eq: "top-navigation" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    topOffcanvasNavigation: contentfulNavigation(
      slug: { eq: "top-offcanvas" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    contentfulBlogPost(slug: { eq: $slug }, node_locale: { eq: $language }) {
      ...ContentfulBlogPostFragment
    }
    legalDoc(language: { eq: $language }, slug: { eq: $slug }) {
      id
      html
    }
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
  }
`;
